import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";

const initialState = {
  indexFolders: "",
  indexTable: {
    data: {},
    status: null,
    error: null,
  },
  selectedFolderName: "test_index",
  loading: {
    status: false,
  },
  fileTableData: [],
  fileNextPageToken: '',
  modelActionstatus: false,
};

export const IndexPageSlice = createSlice({
  name: "IndexPage",
  initialState,
  reducers: {
    setIndexFolders: (state, action) => {
      state.indexFolders = action.payload;
    },
    setIndexTableData: (state, action) => {
      action.payload.forEach((file) => {
        state.fileTableData.push(file);
      });
      let uniqueObjects = state.fileTableData.filter(
        (obj, index, self) => index === self.findIndex((o) => o.FileKey === obj.FileKey),
      );
      state.fileTableData = uniqueObjects;
    },
    setSelectedFolderName: (state, action) => {
      state.selectedFolderName = action.payload;
    },
    setLoadingStatus: (state, action) => {
      state.loading.status = action.payload;
    },
    setUploadFileInTableData: (state, action) => {
      const currentDate = new Date();
      const formattedDate = format(currentDate, 'dd-MM-yyyy hh:mm a');
      const fileSizeInKB = action.payload.file.size / 1024;
      const id = state.fileTableData.length + 1
      const newFile = {
        "id": id,
        "FileKey": action.payload.file.name,
        "File Size": `${fileSizeInKB.toFixed(2)} KB`,
        "Last opened": formattedDate,
        "Uploaded at": formattedDate,
      }
      const exittData = state.fileTableData
      const newData = action.payload.file.name
      const type = action.payload.file.name ? action.payload.file.name.split('.').pop().toUpperCase() : ''
      const findObjectByFileKey = (array, fileKey) => {
        return array.find(obj => obj.FileKey === fileKey);
      };
      const foundObject = findObjectByFileKey(exittData, newData);
      if (foundObject) {
        state.fileTableData = state.fileTableData.filter(
          (i) => i.FileKey !== newData,
        );
        state.fileTableData.push(newFile);
        if (type === 'PDF') {
          const file = action.payload.file.name.split('.')
          const FileName = `${file[0]}.json`
          const jsoId = state.fileTableData.length + 1
          const newJsonFile = {
            "id": jsoId,
            "FileKey": FileName,
            "File Size": `${fileSizeInKB.toFixed(2)} KB`,
            "Last opened": formattedDate,
            "Uploaded at": formattedDate,
          }
          state.fileTableData = state.fileTableData.filter(
            (i) => i.FileKey !== FileName,
          );
          state.fileTableData.push(newJsonFile);
        }
      } else {
        state.fileTableData.push(newFile);
        if (type === 'PDF') {
          const file = action.payload.file.name.split('.')
          const FileName = `${file[0]}.json`
          const jsoId = state.fileTableData.length + 1
          const newJsonFile = {
            "id": jsoId,
            "FileKey": FileName,
            "File Size": `${fileSizeInKB.toFixed(2)} KB`,
            "Last opened": formattedDate,
            "Uploaded at": formattedDate,
          }
          state.fileTableData.push(newJsonFile);
        }

      }
      state.modelActionstatus = false;
    },
    setDeleteFileInTableData: (state, action) => {
      const deleteFiles = action.payload || [];
      deleteFiles.forEach((file) => {
        state.fileTableData = state.fileTableData.filter(
          (i) => i.FileKey !== file,
        );
      });
      state.modelActionstatus = false;
    },
    setModelActionstatus: (state, action) => {
      state.modelActionstatus = action.payload;
    },
    setFileNextPageToken: (state, action) => {
      state.fileNextPageToken = action.payload;
    },
  },
});

export const {
  setIndexFolders,
  setIndexTableData,
  setSelectedFolderName,
  setLoadingStatus,
  setUploadFileInTableData,
  setDeleteFileInTableData,
  setModelActionstatus,
  setFileNextPageToken
} = IndexPageSlice.actions;

export const indexFoldersData = (state) => state.indexPage.indexFolders;
export const indexTableData = (state) => state.indexPage.fileTableData;
export const loadingStatus = (state) => state.indexPage.loading;
export const fileNextPageToken = (state) => state.indexPage.fileNextPageToken;
export const modelActionstatus = (state) => state.indexPage.modelActionstatus;

export default IndexPageSlice.reducer;

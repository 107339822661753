import React, { useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";

import {
  // selectAuthStatus,
  setAuthStatus,
  setUserRole,
  setUsername,
} from "../../Redux/Features/AuthSlice";
// import { getChatHistory } from "../../Redux/Thunk/HomeThunk";
// import { handleStartChat } from "../../Redux/Thunk/HomeThunk";
import AuthFlow from "./AuthFlow";

export default function AppAuth({ children }) {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  // const authStatusState = useSelector(selectAuthStatus);
  const dispatch = useDispatch();

  // console.log("authStatus", authStatus);

  useEffect(() => {
    dispatch(setAuthStatus(authStatus));
  }, [authStatus]);

  async function getUsername() {
    const username = await Auth.currentUserInfo();
    // Set user role to Redux state.
    dispatch(setUserRole(username?.attributes?.['custom:user_role']))
    dispatch(setUsername(username));
  }
  if (authStatus === "configuring") {
    getUsername();
  }
  if (authStatus === "authenticated") {
    getUsername();
    // console.log("username", userName);
    // const payload = {
    //   userid: userName?.username,
    //   chatname: "begin chat",
    // };
    //dispatch(handleStartChat(payload));
    // dispatch(getChatHistory("a488c4b8-80a1-700c-4118-5034dd71f8d8")); //TODO: temp hardcoad
    return <>{children}</>;
  } else if (authStatus === "configuring") {
    return <></>;
  } else {
    return <AuthFlow />;
  }
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authStatus: "configuring",
  username: "",
  email: "",
  activeTabId: "sign-in",
  resetPassword: false,
  resetPasswordSuccess: null,
  userRole:'User',
};

export const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState,
  reducers: {
    setAuthStatus: (state, action) => {
      state.authStatus = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setEmailState: (state, action) => {
      state.email = action.payload;
    },
    setActiveTabId: (state, action) => {
      state.activeTabId = action.payload;
    },
    setResetPassword: (state, action) => {
      state.resetPassword = action.payload;
    },
    setResetPasswordSuccess: (state, action) => {
      state.resetPasswordSuccess = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAuthStatus,
  setUsername,
  setEmailState,
  setActiveTabId,
  setResetPassword,
  setResetPasswordSuccess,
  setUserRole,
} = AuthSlice.actions;

export const selectAuthStatus = (state) => state.auth.authStatus;
export const selectUsername = (state) => state.auth.username;
export const selectEmail = (state) => state.auth.email;
export const selectActiveTabId = (state) => state.auth.activeTabId;
export const selectResetpassword = (state) => state.auth.resetPassword;
export const selectResetPasswordSuccess = (state) =>
  state.auth.resetPasswordSuccess;
export const getUserRole = (state) => state.auth.userRole;
export default AuthSlice.reducer;

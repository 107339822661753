export const primitives = {
  colorCommonBlack: "#000000",
  colorCommonWhite: "#FFFFFF",
  colorPrimaryP4: "#5f4b9b",
  colorPrimaryP3: "#5f4b9b",
  colorPrimaryP2: "#5f4b9b",
  colorPrimaryP1: "#5f4b9b",
  colorWarningLight: "#FF9526",
  colorWarningMain: "#FF7A1D",
  colorWarningDark: "#FD5A1C",
  colorRiskLight: "#FFDD66",
  colorRiskMain: "#FFC64D",
  colorRiskDark: "#FFAF37",
  colorRiskBorder: "#FFDD6",
  colorSuccessLight: "#8DD490",
  colorSuccessMain: "#57D654",
  colorSuccessDark: "#0EB500",
  colorSuccessBorder: "#8BDF6E",
  colorExtendedNeutralN50: "#FAFBFF",
  colorExtendedNeutralN100: "#FFF",
  colorExtendedNeutralN200: "#EDEFF5",
  colorExtendedNeutralN300: "#E6E8F0",
  colorExtendedNeutralN400: "#D8DAE5",
  colorExtendedNeutralN500: "#C1C4D6",
  colorExtendedNeutralN600: "#C1C4D6",
  colorExtendedNeutralN700: "#C1C4D6",
  colorExtendedNeutralN800: "#C1C4D6",
  colorExtendedNeutralN900: "#1E2339",
  colorTextPrimary: "#191919",
  colorTextSecondary: "#737373",
  colorTextDisabled: "#A7A7A7",
  colorTextHint: "#C0C0C0",
  colorBackgroundB0: "#FFF",
  colorBackgroundB1: "#FFF",
  colorBackgroundB2: "#FFFFFF",
  colorDataVisualizationYellow: "#FFA600",
  colorDataVisualizationOrange: "#FF7C43",
  colorDataVisualizationRed: "#F95D6A",
  colorDataVisualizationPink: "#F05A99",
  colorDataVisualizationPinkPurple: "#C366B6",
  colorDataVisualizationPurple: "#8167B7",
  colorDataVisualizationBlue: "#3D64A8",
  colorDataVisualizationMidBlue: "#2F4B7C",
  colorDataVisualizationDarkBlue: "#003F5C",
  colorDataVisualizationBrightBlue: "#00D9C0",
};

import { createSlice } from "@reduxjs/toolkit";

import {} from "../Thunk/HomeThunk";

const initialState = {
  sessionid: "",
  chatHistoryOld: "",
  resetChat: false,
  userSessions: "",
};

export const HomeSlice = createSlice({
  name: "Home",
  initialState,
  reducers: {
    setSessionId: (state, action) => {
      state.sessionid = action.payload;
    },
    setConnectionId: (state, action) => {
      state.connectionId = action.payload;
    },
    setChatHistoryData: (state, action) => {
      state.chatHistoryOld = action.payload;
    },
    setResetChat: (state, action) => {
      state.resetChat = action.payload;
    },
    setUserSessions: (state, action) => {
      state.userSessions = action.payload;
    },
  },
});

export const {
  setSessionId,
  setConnectionId,
  setChatHistoryData,
  setResetChat,
  setUserSessions,
} = HomeSlice.actions;

// export const selectResetChat = (state) => state.home.resetChat;

export default HomeSlice.reducer;

import React, { useState, useEffect, useRef } from 'react';
import { SideNavigation, Icon, Button } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';

import { WizdomTitleLogo } from '../../assets';
import { getUserRole, selectUsername } from '../../Redux/Features/AuthSlice';
import { userSessions } from '../../Redux/Features/HomeSlice';

import {
    handleStartChat,
    handleGetUserSessions,
    handleGetSessionHistory,
    // handleGetSessionDetails,
} from '../../Redux/Thunk/HomeThunk';
import { setResetChat } from '../../Redux/Features/HomeSlice';

export default function SideNavDrawer() {
    const navigate = useNavigate();
    const [activeHref, setActiveHref] = useState('/');
    const [historyNavItems, setHistoryNavItems] = useState([]);
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    // const appLayout = useRef(null);
    const userName = useRef(null);
    userName.current = useSelector(selectUsername);
    const getUserSessions = useSelector((state) => state.home.userSessions);
    // Get user role using Redux state.
    const userRole = useSelector(getUserRole);

    // console.log("userName", userName?.current?.username);
    // console.log("getUserSessions", getUserSessions);

    const cognitoLogout = async () => {
        await Auth.signOut();
        sessionStorage.removeItem('FILE_TBALE_PREFERENCES');
        sessionStorage.removeItem('USER_TBALE_PREFERENCES');
        navigate('/');
        // window.location.reload();
        //console.log("signedout");
    };
    const handleHelp = async () => {
        navigate('/search_13');
    };

    function handleItemClick(item) {
        //console.log("handleItemClickd", item);
        if (item?.href === 'logo') {
            setActiveHref('newConversation');
            navigate('/');
            dispatch(setResetChat(true));
            handleStartNewChat();
            //window.location.reload();
        } else if (item?.href === 'newConversation' || item.href === '/') {
            // console.log("newConversation");
            HandleNewConversation(item?.href);
        } else if (item?.href === 'signOut') {
            cognitoLogout();
        } else if (item?.href?.includes('sessionid')) {
            if (pathname !== '/') navigate('/');
            dispatch(setResetChat(true));
            //console.log("sessionid");
            // const payload = {
            //   userid: userName?.current?.username,
            //   sessionid: item.id,
            // };
            // dispatch(handleGetSessionDetails(payload));
            dispatch(handleGetSessionHistory(item.id));
        } else if (item?.href === 'file_management') {
            navigate('/FileManagement');
        } else if (item?.href === 'user_management') {
            navigate('/UserManagement');
        }
    }

    function HandleNewConversation(href) {
        //console.log("HandleNewConversation");

        navigate('/');
        //window.location.reload();
        if (href === 'newConversation') {
            handleStartNewChat();
            dispatch(setResetChat(true));
        }
    }

    function handleStartNewChat() {
        const payload = {
            userid: userName?.current?.username,
            //chatname: "begin chat",
        };
        dispatch(handleStartChat(payload));
    }

    useEffect(() => {
        if (userName?.current?.username) {
            handleStartNewChat();
            dispatch(handleGetUserSessions(userName?.current?.username));
        }
    }, [userName?.current?.username]);

    useEffect(() => {
        async function fetchData() {
            let items = [];
            if (getUserSessions) {
                for (var i in getUserSessions) {
                    var item = getUserSessions[i];
                    items.push({
                        id: item?.sessionid,
                        type: 'link',
                        text:
                            item?.session_name.length > 34
                                ? item?.session_name.substring(0, 31) + '...'
                                : item?.session_name,
                        href: item?.sessionid,
                    });
                }
                setHistoryNavItems(items);
            }
        }
        fetchData();
    }, [getUserSessions]);
    useEffect(() => {
        setActiveHref(pathname === '/' ? 'newConversation' : pathname);
    }, [pathname]);
    return (
        <>
            <SideNavigation
                activeHref={activeHref}
                header={{
                    logo: { alt: 'logo', src: WizdomTitleLogo, style: { marginTop: '-5px' } },
                    href: 'logo',
                }}
                onFollow={(event) => {
                    if (!event.detail.external) {
                        event.preventDefault();
                        setActiveHref(event.detail.href);
                        handleItemClick(event.detail);
                    }
                }}
                items={[
                    {
                        type: 'link',
                        text: (
                            <h3
                                style={{
                                    fontSize: '16px',
                                    paddingBottom: '10px',
                                    margin: '0px',
                                    fontWeight: '800',
                                    color: activeHref === 'newConversation' ? '#5F4B9B' : 'black',
                                }}
                            >
                                Let’s chat...
                            </h3>
                        ),
                        href: 'newConversation',
                    },
                    {
                        type: 'section',
                        text: (
                            <h3
                                style={{
                                    margin: '0px',
                                    fontWeight: '800',
                                    fontSize: '1.1em',
                                }}
                            >
                                History
                            </h3>
                        ),
                        items: historyNavItems.slice(0, 10),
                    },
                    userRole === 'Admin' ?
                        {
                            type: 'section',
                            text: (
                                <h3
                                    style={{
                                        margin: '0px',
                                        fontWeight: '800',
                                        fontSize: '1.1em',
                                    }}
                                >
                                    Admin
                                </h3>
                            ),
                            items: [
                                {
                                    type: 'link',
                                    text: (
                                        <h3
                                            style={{
                                                margin: '0px',
                                                fontWeight:
                                                    activeHref === '/FileManagement' ? '800' : '400',
                                                color:
                                                    activeHref === '/FileManagement'
                                                        ? '#5f4b9b'
                                                        : 'black',
                                                fontSize: '14px',
                                            }}
                                        >
                                            File management
                                        </h3>
                                    ),
                                    href: 'file_management',
                                },
                                {
                                    type: 'link',
                                    text: (
                                        <h3
                                            style={{
                                                margin: '0px',
                                                fontWeight:
                                                    activeHref === '/UserManagement' ? '800' : '400',
                                                color:
                                                    activeHref === '/UserManagement'
                                                        ? '#5f4b9b'
                                                        : 'black',
                                                fontSize: '14px',
                                            }}
                                        >
                                            User management
                                        </h3>
                                    ),
                                    href: 'user_management',
                                },
                            ],
                        } : {},
                ]}
            />
            <div style={styles.bottomInfoDiv} id='sidenavbutton'>
                <hr style={styles.divider} />
                <Button
                    fontSize='heading-xs'
                    iconAlign='right'
                    iconName='status-info'
                    variant='inline-link'
                    onClick={handleHelp}
                >
                    Help
                </Button>
                <Button
                    fontSize='heading-xs'
                    iconAlign='right'
                    iconName='external'
                    variant='inline-link'
                    onClick={cognitoLogout}
                >
                    Sign out
                </Button>
            </div>
        </>
    );
}
const styles = {
    bottomInfoDiv: {
        position: 'fixed',
        background: 'white',
        height: '100px',
        bottom: '0',
        color: 'rgb(65, 77, 92) !important',
        width: '280px',
        display: 'grid',
        alignItems: 'center',
        padding: '0px 24px 10px 28px',
    },
    divider: {
        display: 'block',
        width: '100%',
        color: '#e9ebed',
        borderTopWidth: '2px',
        opacity: '0.2',
    },
};

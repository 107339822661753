import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { WizdomTitleLogo, EyeHidden, EyeDisplay } from '../../assets';
import { useSelector, useDispatch } from 'react-redux';

import { validatePasswordRequirement } from './auth.config';
import {
    Box,
    Button,
    FormField,
    Flashbar,
    Link,
    Input,
    Spinner,
    RadioGroup,
    Icon,
} from '@cloudscape-design/components';

import {
    selectEmail,
    setEmailState,
    setAuthStatus,
    setResetPassword,
    selectResetPasswordSuccess,
    setResetPasswordSuccess,
} from '../../Redux/Features/AuthSlice';

export default function SignIn() {
    const [signInError, setSignInError] = useState('');
    const [error, setError] = useState({});
    const [email, setEmail] = useState(useSelector(selectEmail));
    const [newPasswordRequired, setNewPasswordRequired] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [pwChar, setPwChar] = useState('');
    const [pwCap, setPwCap] = useState('');
    const [pwSpecial, setPwSpecial] = useState('');
    const [pwNum, setPwNum] = useState('');
    const [password, setPassword] = useState('');
    const [signingIn, setSigningIn] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const dispatch = useDispatch();
    const resetPasswordSuccess = useSelector(selectResetPasswordSuccess);

    useEffect(() => {
        if (pwChar && pwCap && pwNum && email && pwSpecial) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [pwChar, pwCap, pwNum, pwSpecial, email ]);

    function handleFormInputChange(value, setState, id) {
        // console.log("handleFormInputChange: ", value);
        const err = { ...error };
        if (err[id]) delete err[id];
        setSignInError('');
        setError(err);
        setState(value);

        if (id === 'password') validatePasswordRequirement(value, setPwChar, setPwNum, setPwCap, setPwSpecial);
    }

    function formValidator() {
        const errors = {};
        if (!email) errors['email'] = 'Email Field is required';
        if (!password) errors['password'] = 'Password Field is required';
        setError(errors);
        return errors;
    }

    async function handleSignIn() {
        if (Object.keys(formValidator()).length > 0) return;
        setSigningIn(true);
        try {
            const user = await Auth.signIn(email, password);
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                console.log('NEW_PASSWORD_REQUIRED');
                setNewPasswordRequired(true);
                setPwChar('');
                setPwCap('');
                setPwNum('');
                setPwSpecial('');
                setButtonDisabled(true);
                return;
            }
            // console.log("user", user);
            dispatch(setAuthStatus('authenticated'));
            if (resetPasswordSuccess) dispatch(setResetPasswordSuccess(null));
        } catch (error) {
            console.log('error', error.message);
            setSignInError(error.message);
        } finally {
            setSigningIn(false);
        }
    }

    async function signInWithNewPassword() {
        try {
            setSigningIn(true);
            const user = await Auth.signIn(email, password);
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                console.log('What is user?', user);
                await Auth.completeNewPassword(
                    user, // the Cognito User Object
                    newPassword, // the new password
                );
            }
        } catch (error) {
            console.log('Error confirming sign in with new password', error.message);
            setSignInError(error.message);
        } finally {
            setSigningIn(false);
        }
    }

    function handleClearEmail() {
        setEmail('');
        dispatch(setEmailState(''));
    }

    return (
        <Box>
            <Box textAlign='center'>
                <img
                    src={WizdomTitleLogo}
                    alt='WizdomTitleLogo'
                    style={{ height: '100%', width: '350px', maxHeight: '150px' }}
                />
            </Box>
            {signInError && <Flashbar items={[{ header: signInError, type: 'error' }]} />}
            <Box variant='h3' margin={{ bottom: 'm', top: 'm' }}>
                Sign in
            </Box>
            <FormField label='Email' errorText={error['email']}>
                <Input
                    value={email}
                    onChange={(event) => {
                        handleFormInputChange(event.detail.value, setEmail, 'email');
                    }}
                    placeholder='Enter your email'
                />
                <div
                    style={{ position: 'absolute', right: 18, bottom: 16 }}
                    onClick={handleClearEmail}
                >
                    <Icon name='close' data-testid='ClearEmailinput' />
                </div>
            </FormField>
            <Box margin={{ bottom: 'l' }} />
            {newPasswordRequired ? (
                <>
                    <FormField label='Enter new password' errorText={error['password']}>
                        <Input
                            value={newPassword}
                            onChange={(event) => {
                                handleFormInputChange(
                                    event.detail.value,
                                    setNewPassword,
                                    'password',
                                );
                            }}
                            placeholder='Enter your new password'
                            type={showPassword ? 'text' : 'password'}
                        />
                        {showPassword ? (
                            <div
                                style={{ position: 'absolute', right: 18, bottom: 8 }}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <img src={EyeHidden} alt='' dat-testid='EyeHidden'/>
                            </div>
                        ) : (
                            <div
                                style={{ position: 'absolute', right: 20, bottom: 14 }}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <img src={EyeDisplay} alt='' dat-testid='EyeDisplay'/>
                            </div>
                        )}
                    </FormField>
                    <Box
                        textAlign='center'
                        margin={{ bottom: 's' }}
                        padding={{ top: 's', left: 'm' }}
                    >
                        <RadioGroup
                            onChange={({ detail }) => console.log(detail.value)}
                            value={pwChar}
                            items={[
                                {
                                    value: 'pwChar',
                                    label: 'At least 8 characters',
                                    disabled: pwChar ? false : true,
                                },
                            ]}
                        />
                        <RadioGroup
                            onChange={({ detail }) => console.log(detail.value)}
                            value={pwCap}
                            items={[
                                {
                                    value: 'pwCap',
                                    label: 'At least 1 capital letter',
                                    disabled: pwCap ? false : true,
                                },
                            ]}
                        />
                        <RadioGroup
                            onChange={({ detail }) => console.log(detail.value)}
                            value={pwNum}
                            items={[
                                {
                                    value: 'pwNum',
                                    label: 'At least 1 number',
                                    disabled: pwNum ? false : true,
                                },
                            ]}
                        />
                        <RadioGroup
                            onChange={({ detail }) => console.log(detail.value)}
                            value={pwSpecial}
                            items={[
                                {
                                    value: 'pwSpecial',
                                    label: 'At least 1 symbol character',
                                    disabled: pwSpecial ? false : true,
                                },
                            ]}
                        />
                    </Box>
                </>
            ) : (
                <FormField label='Password' errorText={error['password']}>
                    <Input
                        value={password}
                        onChange={(event) => {
                            handleFormInputChange(event.detail.value, setPassword, 'password');
                        }}
                        placeholder='Enter your password'
                        type={showPassword ? 'text' : 'password'}
                    />
                    <div
                        style={{ position: 'absolute', right: 18, bottom: 16 }}
                        onClick={() => setPassword('')}
                    >
                        <Icon name='close' data-testid='ClearPassword' />
                    </div>
                    {showPassword ? (
                        <div
                            style={{ position: 'absolute', right: 40, bottom: 8 }}
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            <img src={EyeHidden} alt='' data-testid='EyeHidden' />
                        </div>
                    ) : (
                        <div
                            style={{ position: 'absolute', right: 40, bottom: 14 }}
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            <img src={EyeDisplay} alt='' data-testid='EyeDisplay' />
                        </div>
                    )}
                </FormField>
            )}
            <div style={{ marginBottom: '45px' }} />
            <div className='sign-in-footer'>
                <div
                    onClick={() => {
                        dispatch(setResetPassword(true));
                        dispatch(setEmailState(email));
                        setPwChar('');
                        setPwCap('');
                        setPwNum('');
                        setPwSpecial('');
                    }}
                    className='forgot-password'
                >
                    <Link data-id='forgot-password-button'>Forgot your password?</Link>
                </div>
                <Box textAlign='center'>
                    {signingIn ? (
                        <Button variant='primary' data-id='auth-button'>
                            <Spinner />
                        </Button>
                    ) : (
                        <Button
                            variant='primary'
                            data-id={!email || !password ? 'auth-button-disabled' : 'auth-button'}
                            onClick={newPasswordRequired ? signInWithNewPassword : handleSignIn}
                            disabled={
                                newPasswordRequired ? buttonDisabled : !email || !password
                                // !email || !password ? true : false
                            }
                        >
                            Sign in
                        </Button>
                    )}
                </Box>
            </div>
        </Box>
    );
}

import React from "react";
import { Container } from "@cloudscape-design/components";
import { useSelector } from "react-redux";

import { selectResetpassword } from "../../Redux/Features/AuthSlice";
import SignIn from "./SignIn";
import ResetPassword from "./ResetPassword";

export default function AuthFlow() {
  const resetPassword = useSelector(selectResetpassword);

  return (
    <div className="authflow-wrapper">
      {resetPassword === true ? (
        <Container>
          <ResetPassword />
        </Container>
      ) : (
        <Container>
          <SignIn />
        </Container>
      )}
    </div>
  );
}

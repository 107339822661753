import { primitives } from '../colors';

export const colorBorder = {
  //color/Border/Button/Normal
  colorBorderButtonNormalDefault: { light: primitives.colorPrimaryP4, dark: '' },
  //color/Border/Button/Primary
  colorBorderButtonPrimaryDisabled: { light: '', dark: '' },
  //color/Border/Container
  colorBorderContainerTop: { light: '', dark: '' },
  //color/Border/Control
  colorBorderControlDefault: { light: '', dark: '' },
  //color/Border/Divider
  colorBorderDividerDefault: { light: '', dark: '' },
  //color/Border/DropdownItem
  colorBorderDropdownItemHover: { light: '', dark: '' },
  //color/Border/Input
  colorBorderInputDefault: { light: '', dark: '' },
  //color/Border/Item
  colorBorderItemFocused: { light: '', dark: '' },
  colorBorderItemSelected: { light: '', dark: '' },
  //color/Border/Status
  colorBorderStatusError: { light: '', dark: '' },
  colorBorderStatusInfo: { light: '', dark: '' },
  colorBorderStatusSuccess: { light: '', dark: '' },
  colorBorderStatusWarning: { light: '', dark: '' },
};

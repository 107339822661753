import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userTable: {
        data: {},
        status: null,
        error: null,
    },
    loading: {
        status: false,
    },
    userTableData: [],
    totalUserCount: 0,
    nextPageToken: null,
};

export const UserPageSlice = createSlice({
    name: 'IndexPage',
    initialState,
    reducers: {
        setUserFolders: (state, action) => {
            state.indexFolders = action.payload;
        },
        setUserTableData: (state, action) => {
            action.payload.user_list.forEach((user) => {
                state.userTableData.push(user);
            });
            let uniqueObjects = state.userTableData.filter(
                (obj, index, self) => index === self.findIndex((o) => o.Username === obj.Username),
            );
            state.userTableData = uniqueObjects;
        },
        setCreateUserData: (state, action) => {
            const dateObject = new Date();
            const formattedDateString = dateObject.toISOString();
            const newUser = {
                Username: action.payload.userid,
                Attributes: [
                    {
                        Name: 'sub',
                        Value: action.payload.userid,
                    },
                    {
                        Name: 'email_verified',
                        Value: 'true',
                    },
                    {
                        Name: 'given_name',
                        Value: action.payload.first_name,
                    },
                    {
                        Name: 'family_name',
                        Value: action.payload.last_name,
                    },
                    {
                        Name: 'custom:user_role',
                        Value: action.payload.user_role,
                    },
                    {
                        Name: 'email',
                        Value: action.payload.email,
                    },
                ],
                UserCreateDate: formattedDateString,
                UserLastModifiedDate: formattedDateString,
                Enabled: true,
                UserStatus: 'FORCE_CHANGE_PASSWORD',
            };
            state.userTableData.push(newUser);
        },
        setUpdateUserTableData: (state, action) => {
            const existingUserIndex = state.userTableData.findIndex(
                (existingUser) => existingUser.Username === action.payload.userid,
            );
            if (existingUserIndex !== -1) {
                const updatedAttributes = [
                    {
                        Name: 'given_name',
                        Value: action.payload.first_name,
                    },
                    {
                        Name: 'family_name',
                        Value: action.payload.last_name,
                    },
                    {
                        Name: 'custom:user_role',
                        Value: action.payload.user_role,
                    },
                    {
                        Name: 'email',
                        Value: action.payload.email,
                    },
                ];
                // Update existing user
                const updateUser = {
                    ...state.userTableData[existingUserIndex],
                    Attributes: updatedAttributes,
                    //   Attributes: state.userTableData[existingUserIndex].Attributes.map((attribute) => {
                    //     console.log(attribute)
                    //     // Find the attribute with the specified Name and update its Value
                    //     const updatedAttribute = updatedAttributes.find((updatedAttr) => updatedAttr.Name === attribute.Name);

                    //     if (updatedAttribute) {
                    //       return {
                    //         ...attribute,
                    //         Value: updatedAttribute.Value,
                    //       };
                    //     }

                    //     return attribute;
                    //   }),
                };
                //console.log(updateUser)
                state.userTableData[existingUserIndex] = updateUser;
                //state.userTableData[existingUserIndex] = action.payload;
            }
        },
        deleteUsersInTableData: (state, action) => {
            const userIDsToDelete = action.payload || [];
            userIDsToDelete.forEach((userID) => {
                state.userTableData = state.userTableData.filter(
                    (user) => user.Username !== userID,
                );
            });
        },
        setTotalUserCount: (state, action) => {
            state.totalUserCount = action.payload;
        },
        setUserNextPageToken: (state, action) => {
            state.nextPageToken = action.payload;
        },
        setLoadingStatus: (state, action) => {
            state.loading.status = action.payload;
        },
    },
});

export const {
    setUserFolders,
    setUserTableData,
    setLoadingStatus,
    setCreateUserData,
    setTotalUserCount,
    setUserNextPageToken,
    deleteUsersInTableData,
    setUpdateUserTableData,
} = UserPageSlice.actions;

export const userTableData = (state) => state.userPage.userTableData;
export const totalUser = (state) => state.userPage.totalUserCount;
export const loadingStatus = (state) => state.userPage.loading;
export const createUserData = (state) => state.userPage.createUserData;
export const userListNextPageToken = (state) => state.userPage.nextPageToken;
export default UserPageSlice.reducer;

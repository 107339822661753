import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./Features/AuthSlice";
import homeReducer from "./Features/HomeSlice";
import IndexPageReducer from "./Features/IndexPageSlice";
import flashReducer from "./Features/FlashSlice";
import  UserPageSlice  from "./Features/UsePageSlice";
export const Store = configureStore({
  reducer: {
    auth: authReducer,
    home: homeReducer,
    indexPage: IndexPageReducer,
    flashbar: flashReducer,
    userPage: UserPageSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

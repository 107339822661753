import { createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
import { Auth } from "aws-amplify";

import {
  setSessionId,
  setChatHistoryData,
  setUserSessions,
} from "../Features/HomeSlice";

const API_URL = window.APP_CONFIG.REACT_APP_API_URL;
const LLM_API_URL = window.APP_CONFIG.REACT_APP_API_URL;

export const getCognitoToken = async () => {
  const userToken = await Auth.currentSession();
  const id = userToken.getIdToken();
  const jwt = id.getJwtToken();
  // console.log(jwt);
  return jwt;
};

export const handleStartChat = createAsyncThunk(
  "HomeThunk/handleStartChat",
  async (data, { dispatch }) => {
    // console.log("handleStartChat:", data);
    const headers = {
      "Content-Type": "application/json",
      Authorization: await getCognitoToken(),
    };

    try {
      let response = await fetch(API_URL + "/chathistory", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });
      response = await response.json();
      // console.log("API response:", response);

      dispatch(setSessionId(response.sessionid));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

export const handleGetSessionHistory = createAsyncThunk(
  "HomeThunk/handleGetSessionHistory",
  async (data, { dispatch }) => {
    // console.log("handleGetSessionHistory:", data);
    const headers = {
      "Content-Type": "application/json",
      Authorization: await getCognitoToken(),
    };

    try {
      let response = await fetch(
        `${API_URL}/chathistory?sessionid=${encodeURIComponent(data)}`,
        {
          method: "GET",
          headers: headers,
          //body: JSON.stringify(data),
        }
      );
      response = await response.json();
      dispatch(setChatHistoryData(response));
      dispatch(setSessionId(data));
      // console.log("API response GetSessionHistory:", response);
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

export const handleGetUserSessions = createAsyncThunk(
  "HomeThunk/handleGetUserSessions",
  async (data, { dispatch }) => {
    // console.log("handleGetUserSessions:", data);
    const headers = {
      "Content-Type": "application/json",
      Authorization: await getCognitoToken(),
    };

    try {
      let response = await fetch(`${API_URL}/chathistory?userid=${data}`, {
        method: "GET",
        headers: headers,
        //body: JSON.stringify(data),
      });
      response = await response.json();
      // console.log("API response GetUserSessions:", response);
      dispatch(setUserSessions(response));
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

export const handleChat = createAsyncThunk(
  "HomeThunk/handleChat",
  async (data, { dispatch, getState }) => {
    const payload = {
      question: data,
      sessionid: getState().home?.sessionid,
      connectionId: getState().home?.connectionId
    };
    // console.log("handleChat:", payload);
    const headers = {
      "Content-Type": "application/json",
      Authorization: await getCognitoToken(),
    };

    try {
      let response = await fetch(LLM_API_URL + "/chat", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      response = await response.json();
      // console.log("API response:", response);
      dispatch(handleGetUserSessions(getState().auth?.username?.username));
      return response.result;
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

export const handleImage = createAsyncThunk(
  "HomeThunk/handleImage",
  async (data, { dispatch, getState }) => {
    // console.log("handleImage:", data);
    const payload = {
      image: data.image,
      sessionid: getState().home?.sessionid,
    };
    // console.log("handleImage:", payload);
    const headers = {
      "Content-Type": "application/json",
      Authorization: await getCognitoToken(),
    };
    try {
      let response = await fetch(LLM_API_URL + "/image", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      response = await response.json();
      // console.log("API response:", response.result);
      dispatch(handleGetUserSessions(getState().auth?.username?.username));
      return response.result;
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

export const handleSemanticSearch = createAsyncThunk(
  "HomeThunk/handleSemanticSearch",
  async (data, { dispatch, getState }) => {
    // console.log("handleSemanticSearch:", data);
    const payload = {
      search: data,
      sessionid: getState().home?.sessionid,
      connectionId: getState().home?.connectionId
    };
    // console.log("handleSemanticSearch:", payload);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: await getCognitoToken(),
      };
      let response = await fetch(LLM_API_URL + "/semantic_search", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      response = await response.json();
      // console.log("API response:", response.result);
      dispatch(handleGetUserSessions(getState().auth?.username?.username));
      return response.result;
    } catch (error) {
      console.error("API error:", error);
    }
  }
);

//need to change this api call below to new api for query
export const handleQuery = createAsyncThunk(
  "HomeThunk/handleQuery",
  async (data, { dispatch, getState }) => {
    // console.log("handleChat:", data);
    const payload = {
      query: data,
      sessionid: getState().home?.sessionid,
      connectionId: getState().home?.connectionId
    };
    // console.log("handleChat:", payload);
    const headers = {
      "Content-Type": "application/json",
      Authorization: await getCognitoToken(),
    };

    try {
      let response = await fetch(LLM_API_URL + "/query", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      response = await response.json();
      // console.log("API response:", response);
      dispatch(handleGetUserSessions(getState().auth?.username?.username));
      return response.result;
    } catch (error) {
      console.error("API error:", error);
    }
  }
);
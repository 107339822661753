export const handleAuthError = (error) => {
    let errorMessage;
  
    switch (error.name) {
      case 'UserNotFoundException':
        errorMessage = 'User not found. Check email/username.';
        break;
      case 'UsernameExistsException':
        errorMessage = 'User already exists in System';
        break;
      case 'NotAuthorizedException':
        errorMessage = 'Incorrect password. Try again.';
        break;
      case 'PasswordResetRequiredException':
        errorMessage = 'Password reset required. Check email.';
        break;
      case 'UserNotConfirmedException':
        errorMessage = 'User not confirmed. Verify email.';
        break;
      case 'CodeMismatchException':
        errorMessage = 'Invalid confirmation code. Retry.';
        break;
      case 'ExpiredCodeException':
        errorMessage = 'Confirmation code expired. Resend code.';
        break;
      case 'InvalidParameterException':
        errorMessage = 'Invalid input. Check credentials.';
        break;
      case 'InvalidPasswordException':
        errorMessage = 'Invalid password. Follow policy.';
        break;
      case 'TooManyFailedAttemptsException':
        errorMessage = 'Too many failed attempts. Wait.';
        break;
      case 'TooManyRequestsException':
        errorMessage = 'Request limit reached. Wait and retry.';
        break;
      case 'LimitExceededException':
        errorMessage = 'User pool full. Retry later.';
        break;
      default:
        errorMessage = 'Unknown error. Contact support.';
    }
    return errorMessage;
  };

  export function validatePasswordRequirement(pw, setPwChar, setPwNum, setPwCap, setPwSpecial) {
    const has1NumberRegex = /\d/;
    const hasCapRegex = /[A-Z]/;
    const hasSpecialRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    // If pw length >= 8
    if (pw.length >= 8) {
      setPwChar('pwChar');
    } else {
      setPwChar('');
    }
  
    // If pw has min 1 number
    if (has1NumberRegex.test(pw)) {
      setPwNum('pwNum');
    } else {
      setPwNum('');
    }
  
    // If pw has min 1 uppercase
    if (hasCapRegex.test(pw)) {
      setPwCap('pwCap');
    } else {
      setPwCap('');
    }
    // If pw has min 1 special character
    if (hasSpecialRegex.test(pw)) {
      setPwSpecial('pwSpecial');
    } else {
      setPwSpecial('');
    }
  }
  